import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "../../atomic/buttons/button";
import {CSSTransition} from "react-transition-group";
import FadeInText from "../../atomic/text/fade-in-text";
import BodySizeSlider from "../../composite/body-size-slider";
import {setDisplayModal, setModalData, setModalType} from "../../../redux/slices/displayModalsSlice";
import {useDispatch} from "react-redux";
import IconButton from "../../atomic/buttons/icon-button";
import QuestionMarkIcon from '../../../assets/icons/question-mark.svg';
import LeftArrowIcon from '../../../assets/icons/left-arrow.svg';
import useDressingRoom from "../../../hooks/useDressingRoom";
import ManModel2 from "../../../assets/images/male-model-02.png";
import CirclePointer from "../../atomic/circle-pointer";
import BodyPartPointer from "../../composite/body-part-pointer";

const BodySizes = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {getUserBodyMeasurements, userBodyMeasurements, updateUserBodyMeasurements} = useDressingRoom();
    const [displayBodyPartsOptions, setDisplayBodyPartsOptions] = useState(true);
    const [displayUpperBodySizingSection, setDisplayUpperBodySizingSection] = useState(false);
    const [selectedItems, setSelectedItems] = useState<BodyMeasurementItem[]>([]);
    const [selectedBodyRegion, setSelectedBodyRegion] = useState({name: ''})
    const [selectedBodyRegions, setSelectedBodyRegions] = useState<string[]>([]);

    useEffect(() => {
        getUserBodyMeasurements();
    }, []);

    console.log(userBodyMeasurements);

    const findMeasurementValue = (bodyRegionName: string, bodyPartName: string) => {
        try {
            return userBodyMeasurements?.body_measurements?.find(
                bm => bm.name === bodyRegionName)?.items?.find(i => i.name === bodyPartName)
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }


    return (
        <div
            className={`p-6 ${i18n.language === "fa" ? "text-right" : "text-left"}`}
        >
            <div className="min-h-[140px]"
                 style={{direction: i18n?.language === 'fa' ? 'rtl' : 'ltr'}}>
                <div className={'flex justify-between items-center w-full'}>
                    <div className={'flex gap-1 items-center'}>
                        <h1 className="font-bold text-lg pt-1">{t("body_sizes")}</h1>
                        <IconButton
                            onClick={() => {
                                dispatch(setDisplayModal(true));
                                dispatch(setModalType('guideline'));
                                dispatch(setModalData(
                                        {
                                            title: t('measurement_guideline_title'),
                                            description: t('measurement_guideline_description'),
                                            tips: t('measurement_guideline_tips', {returnObjects: true})
                                        }
                                    )
                                )
                            }}
                            icon={<img src={QuestionMarkIcon} alt={'help'}/>}
                        />
                    </div>
                    <CSSTransition
                        in={displayUpperBodySizingSection}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                        onExited={() => setDisplayBodyPartsOptions(true)}
                    >
                        <IconButton
                            onClick={() => setDisplayUpperBodySizingSection(false)}
                            // variety={'primary'}
                            // title={t('back')}
                            icon={<img src={LeftArrowIcon} alt={'back'} /> }
                        />
                    </CSSTransition>
                </div>
                <div className="text-sm flex flex-col gap-y-2 pt-2">
                    <FadeInText>
                        <p>
                            {t("body_sizes_description_1")} {t("body_sizes_description_2")}
                        </p>
                    </FadeInText>
                    <FadeInText delay={.2}>
                        <p className={'font-bold text-center'}>
                            {t("body_sizes_description_3")}
                        </p>
                    </FadeInText>
                </div>
            </div>

            <CSSTransition
                in={displayBodyPartsOptions}
                timeout={300}
                classNames="fade"
                unmountOnExit
                onExited={() => setDisplayUpperBodySizingSection(true)}
            >
                <>
                    {/*<div className="grid grid-cols-4 gap-4 px-20">*/}
                    <div className="flex px-20 justify-center">
                        <div className="relative inline-block">
                            {/* The image */}
                            <img src={ManModel2} alt="Your description" className="w-80 h-auto"/>

                            {
                                userBodyMeasurements?.body_measurements?.map((br, index) =>
                                    <div
                                        className={`flex-col flex justify-end items-end absolute w-full`}
                                        style={{
                                            top: br?.top,
                                            left: `${
                                                i18n?.language === 'en' ? 
                                                    `${br?.left ? (
                                                         ((parseFloat(br?.left?.replace('%', '')) > 0 ? -.75 : -1.33
                                                        ) * parseFloat(br?.left?.replace('%', '')))) : 100}%` 
                                                    : br?.left
                                            }`
                                        }}
                                    >
                                        <BodyPartPointer
                                            key={index}
                                            percentage={
                                                Math.round(br?.items?.reduce(
                                                    (totalValue, currentValue) =>
                                                        totalValue + (currentValue.percentage * (currentValue?.value !== 0 ? 1 : 0)),
                                                    0
                                                ))
                                            }
                                            onClickHandle={() => {
                                                setSelectedItems(br?.items)
                                                setSelectedBodyRegion({name: br?.name})
                                                setDisplayBodyPartsOptions(false)
                                            }}
                                            onClickDisplaySizes={() =>
                                                selectedBodyRegions?.includes(br?.name) ?
                                                    setSelectedBodyRegions(selectedBodyRegions?.filter(a => a !== br?.name)) :
                                                    setSelectedBodyRegions([...selectedBodyRegions, br?.name])}
                                            isDisplaySizes={selectedBodyRegions?.includes(br?.name)}
                                            label={`${i18n?.language === 'en' ? br.en_name : br.fa_name}`}
                                            description={`${i18n?.language === 'en' ? br.en_description : br.fa_description}`}
                                        />
                                    </div>
                                )
                            }


                            {/*HEAD*/}
                            {
                                selectedBodyRegions?.includes('HEAD_AND_NECK') &&
                                <>
                                    <CirclePointer
                                        id={'HEAD_AND_NECK_HEAD_CIRCUMFERENCE'}
                                        left={'26%'}
                                        top={'4%'}
                                        textHorizontalPosition={'left'}
                                        text={findMeasurementValue('HEAD_AND_NECK', 'HEAD_CIRCUMFERENCE')}
                                    />
                                    <CirclePointer
                                        id={'HEAD_AND_NECK_NECK_CIRCUMFERENCE'}
                                        left={'59%'}
                                        top={'11%'}
                                        textHorizontalPosition={'right'}
                                        text={findMeasurementValue('HEAD_AND_NECK', 'NECK_CIRCUMFERENCE')}

                                    />
                                </>
                            }

                            {/*UPPER*/}
                            {
                                selectedBodyRegions?.includes('UPPER_BODY') &&
                                <>
                                    <CirclePointer
                                        id={'UPPER_BODY_SHOULDER_WIDTH'}
                                        left={'24%'}
                                        top={'13%'}
                                        textHorizontalPosition={'left'}
                                        text={findMeasurementValue('UPPER_BODY', 'SHOULDER_WIDTH')}
                                    />
                                    <CirclePointer
                                        id={'UPPER_BODY_SHORT_SLEEVE_LENGTH'}
                                        left={'16%'}
                                        top={'18%'}
                                        textHorizontalPosition={'left'}
                                        text={findMeasurementValue('UPPER_BODY', 'SHORT_SLEEVE_LENGTH')}
                                    />
                                    <CirclePointer
                                        id={'UPPER_BODY_ARMHOLE'}
                                        left={'19%'}
                                        top={'26%'}
                                        textHorizontalPosition={'left'}
                                        text={findMeasurementValue('UPPER_BODY', 'ARMHOLE')}
                                    />
                                    <CirclePointer
                                        id={'UPPER_BODY_LONG_SLEEVE_LENGTH'}
                                        left={'74%'}
                                        top={'33%'}
                                        text={findMeasurementValue('UPPER_BODY', 'LONG_SLEEVE_LENGTH')}
                                    />
                                    <CirclePointer
                                        id={'UPPER_BODY_CHEST_OR_BUST'}
                                        left={'50%'}
                                        top={'26%'}
                                        text={findMeasurementValue('UPPER_BODY', 'CHEST_OR_BUST')}
                                        opacity={'opacity-60'}
                                    />
                                    <CirclePointer
                                        id={'UPPER_BODY_TORSO_LENGTH'}
                                        left={'40%'}
                                        top={'34%'}
                                        text={findMeasurementValue('UPPER_BODY', 'TORSO_LENGTH')}
                                        opacity={'opacity-60'}
                                    />
                                    <CirclePointer
                                        id={'UPPER_BODY_WAIST'}
                                        left={'50%'}
                                        top={'40%'}
                                        text={findMeasurementValue('UPPER_BODY', 'WAIST')}
                                        opacity={'opacity-60'}
                                    />
                                </>}

                            {/*LOWER*/}
                            {
                                selectedBodyRegions?.includes('LOWER_BODY') &&
                                <>
                                    <CirclePointer
                                        id={''}
                                        left={'23%'}
                                        top={'50%'}
                                        textHorizontalPosition={'left'}
                                        textVerticalPosition={'bottom'}
                                        text={findMeasurementValue('LOWER_BODY', 'HIPS')}
                                        opacity={'opacity-70'}
                                    />
                                    <CirclePointer
                                        id={''}
                                        left={'50%'}
                                        top={'50%'}
                                        textVerticalPosition={'bottom'}
                                        text={findMeasurementValue('LOWER_BODY', 'RISE')}
                                        opacity={'opacity-60'}
                                    />
                                    <CirclePointer
                                        id={''}
                                        left={'67%'}
                                        top={'51%'}
                                        text={findMeasurementValue('LOWER_BODY', 'OUTSEAM')}
                                    />
                                    <CirclePointer
                                        id={''}
                                        left={'66%'}
                                        top={'62%'}
                                        textVerticalPosition={'bottom'}
                                        text={findMeasurementValue('LOWER_BODY', 'THIGH')}
                                    />
                                    <CirclePointer
                                        id={''}
                                        left={'50%'}
                                        top={'75%'}
                                        text={findMeasurementValue('LOWER_BODY', 'INSEAM')}
                                        opacity={'opacity-70'}
                                    />
                                </>}

                            {/*FEET*/}
                            {
                                selectedBodyRegions?.includes('FEET') &&
                                <>
                                    <CirclePointer
                                        id={''}
                                        left={'63%'}
                                        top={'85%'}
                                        text={findMeasurementValue('FEET', 'FOOT_LENGTH')}
                                        opacity={'opacity-60'}
                                    />
                                    <CirclePointer
                                        id={''}
                                        left={'45%'}
                                        top={'95%'}
                                        text={findMeasurementValue('FEET', 'FOOT_WIDTH')}
                                        opacity={'opacity-60'}
                                        textVerticalPosition={'bottom'}
                                    />
                                </>
                            }

                        </div>
                    </div>
                </>
            </CSSTransition>

            <CSSTransition
                in={displayUpperBodySizingSection}
                timeout={300}
                classNames="fade"
                unmountOnExit
                onExited={() => setDisplayBodyPartsOptions(true)}
            >
                <BodySizeSlider
                    items={selectedItems}
                    selectedBodyRegion={selectedBodyRegion}
                    updateUserBodyMeasurements={updateUserBodyMeasurements}
                />
            </CSSTransition>
        </div>
    );
};

export default BodySizes;
