import {useTranslation} from "react-i18next";
import Layout from "../../screen/layout";
import React, {useEffect} from "react";
import Title from "../../atomic/title";
import useDressingRoom from "../../../hooks/useDressingRoom";
import {useNavigate, useParams} from "react-router-dom";
import Button from "../../atomic/buttons/button";

const cellStyle: React.CSSProperties = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center' as const, // Set textAlign as a constant string
};

// Function to set color based on fit status
const getFitColor = (fitStatus: "Fit" | "Tight" | "Loose") => {
    switch (fitStatus) {
        case "Fit":
            return "green";
        case "Tight":
            return "orange";
        case "Loose":
            return "red";
        default:
            return "black";
    }
};

// Sample data for demonstration
const sampleSizes: SizeFit[] = [
    {
        size_label: "M",
        fit_details: {
            CHEST_OR_BUST: {fit_status: "Fit", member_size: 51, clothe_size: 51, priority: 1},
            WAIST: {fit_status: "Tight", member_size: 50, clothe_size: 48, priority: 1},
            TORSO_LENGTH: {fit_status: "Fit", member_size: 69, clothe_size: 69, priority: 1},
        },
        overall_fit: "Fit"
    },
    // Add more SizeFit objects here
];

const ClotheDetails = () => {
    const {t} = useTranslation();
    const {getClotheDetails, clotheDetails, tryOn, tryOnResult} = useDressingRoom();
    const {id} = useParams();

    const onClickTryOn = (id: string) => {
        try {
            tryOn(id)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        id && getClotheDetails(id);
    }, [id]);

    const clotheBodyParts = Array.from(
        new Set(clotheDetails?.sizes.flatMap(size => size.measurements?.map(m => m.body_part) || []))
    );

    const bodyParts = Array.from(
        new Set(tryOnResult?.flatMap((size) => Object.keys(size.fit_details)))
    );

    return (
        <Layout>
            <>
                <Title context={t("dressing_room")}/>
                {clotheDetails &&
                    <div className={'flex gap-3'}>
                        <div className={'flex flex-col items-center justify-center rounded p-5 m-5'}>
                            <div className={'flex gap-4'}>
                                {clotheDetails?.images?.map(clothe => <img
                                    src={process.env.REACT_APP_API_BASE_URL + clothe?.image_url}
                                    alt={clothe?.image_url}
                                    className={'w-44 h-44'}
                                />)}
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 border-b text-sm'}>
                                <p>{t('type')}: </p>
                                <p>{clotheDetails?.type}</p>
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 border-b text-sm'}>
                                <p>{t('color')}: </p>
                                <p>{clotheDetails?.color}</p>
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 border-b text-sm'}>
                                <p>{t('fabric')}: </p>
                                <p>{clotheDetails?.fabric}</p>
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 border-b text-sm'}>
                                <p>{t('pattern')}: </p>
                                <p>{clotheDetails?.pattern}</p>
                            </div>
                            <Button
                                variety={'primary'}
                                onClick={() => onClickTryOn(clotheDetails?.id?.toString())}
                                title={t('try on')}
                                customClassName={'mt-3'}
                            />
                        </div>
                        <div className={'flex flex-col items-center justify-center rounded p-5 m-5 w-2/3'}>
                            {clotheDetails?.sizes?.length > 0 && clotheBodyParts?.length > 0 &&
                                <table style={{borderCollapse: 'collapse', width: '100%'}}>
                                    <thead>
                                    <tr>
                                        <th style={{border: '1px solid black', padding: '8px'}}>Size</th>
                                        {clotheBodyParts.map((bodyPart) => (
                                            <th key={bodyPart}
                                                style={{border: '1px solid black', padding: '8px'}}>{bodyPart}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {clotheDetails?.sizes.map((size) => (
                                        <tr key={size.size_label}>
                                            <td style={{
                                                border: '1px solid black',
                                                padding: '8px',
                                                textAlign: 'center'
                                            }}>{size.size_label}</td>
                                            {clotheBodyParts.map((bodyPart) => {
                                                // Find measurement for the current body part
                                                const measurement = size.measurements?.find(m => m.body_part === bodyPart);
                                                return (
                                                    <td key={bodyPart} style={{
                                                        border: '1px solid black',
                                                        padding: '8px',
                                                        textAlign: 'center'
                                                    }}>
                                                        {measurement ? measurement.size_number : 'N/A'} <span
                                                        className={'text-xs'}>({measurement?.size_unit})</span>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>}

                            {tryOnResult?.length > 0 &&
                                <table style={{width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd'}}
                                       className={'mt-8'}>
                                    <thead>
                                    <tr>
                                        <th style={cellStyle}>Size Label</th>
                                        <th style={cellStyle}>Overall Fit</th>
                                        {bodyParts.map((part) => (
                                            <th key={part} style={cellStyle}>{part.replace("_", " ")} Fit Status</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tryOnResult.map((size) => (
                                        <tr key={size.size_label}>
                                            <td style={cellStyle}>{size.size_label}</td>
                                            <td style={{
                                                ...cellStyle,
                                                color: getFitColor(size.overall_fit)
                                            }}>{size.overall_fit}</td>
                                            {bodyParts.map((part) => (
                                                <td key={part} style={cellStyle}>
                                                    {size.fit_details[part]?.fit_status || "N/A"} <br/>
                                                    <small>
                                                        User: {size.fit_details[part]?.member_size || "N/A"},
                                                        Clothe: {size.fit_details[part]?.clothe_size || "N/A"}
                                                    </small>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>}

                        </div>
                    </div>
                }
            </>
        </Layout>
    );
};

export default ClotheDetails;
