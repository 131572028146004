import React from 'react';
import {useTranslation} from "react-i18next";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Button from "../../atomic/buttons/button";
import EditIcon from "../../../assets/icons/edit.svg"
import CloseEyeIcon from "../../../assets/icons/close-eye.svg";
import OpenEyeIcon from "../../../assets/icons/open-eye.svg";

const BodyPartPointer = ({percentage, label, description, onClickHandle, onClickDisplaySizes, isDisplaySizes}: {
    percentage: number;
    label: string;
    description: string;
    onClickHandle: () => void;
    onClickDisplaySizes: () => void;
    isDisplaySizes: boolean;
}) => {
    const {i18n, t} = useTranslation()

    return (
        <div
            className={`flex items-center w-full rounded-lg leading-[0px] text-[.8rem] p-2 mt-5 transition duration-300 gap-1`}
            style={{
                direction: i18n.language === 'fa' ? 'rtl' : 'ltr',
                justifyContent: i18n.language === 'fa' ? 'end' : 'start',
            }}
        >
            <div style={{width: 50, height: 50}}>
                <CircularProgressbar
                    className={'items-center flex'}
                    strokeWidth={12}
                    styles={buildStyles(
                        {
                            pathColor: '#222',
                            textColor: '#123'
                        })}
                    value={percentage}
                    text={`${percentage} %`}
                />
            </div>
            <div className={'px-1'}>
                <p className={'text-xs font-bold text-start'}>{label}</p>
                <p className={'text-[10px] leading-3 mt-1 text-gray-500 text-start'}>{description}</p>
                <div className={'flex gap-1 !p-1'}>
                    <Button
                        variety={'tertiary'}
                        customClassName={'text-xs !p-2 !py-1 mt-1 !w-fit min-w-[34px]'}
                        onClick={() => onClickHandle()}
                        title={t('edit')}
                        icon={<img src={EditIcon} alt={'edit-icon'} width={20} height={20}/>}
                    />
                    <Button
                        variety={'tertiary'}
                        customClassName={'text-xs !p-2 !py-1 mt-1 !w-fit min-w-[34px]'}
                        onClick={() => onClickDisplaySizes()}
                        title={isDisplaySizes ? t('hidden') : t('visible')}
                        icon={
                            <img
                                src={isDisplaySizes ? CloseEyeIcon : OpenEyeIcon}
                                alt={'eye-icon'}
                                width={20}
                                height={20}
                            />
                        }
                    />
                </div>

            </div>
        </div>
    )
        ;
};

export default BodyPartPointer;
