import {useState} from "react";
import {useLazyGetDataQuery, usePutDataMutation} from "../api/services/apiSlice";
import useToast from "./useToast";

export default function useDressingRoom() {
    const [getApi, responseGetApi] = useLazyGetDataQuery();
    const [putApi, responsePutApi] = usePutDataMutation();
    const [userBodyMeasurements, setUserBodyMeasurements] = useState<UserBodyMeasurements>();
    const {showToastMessage, showToast} = useToast();
    const [clothes, setClothes] = useState<Clothes>([]);
    const [clotheDetails, setClotheDetails] = useState<Clothe>();
    const [tryOnResult, setTryOnResult] = useState<SizeFit[]>([]);

    // const prepareUserBodyMeasurements = (input: UserBodyMeasurements) => {
    //     try {
    //
    //         UserBodyMeasurements?.map((measurement) => {...measurement, computePosition})
    //
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    const updateUserBodyMeasurements = async (inputData: UpdateUserBodyMeasurementsRequestBody) => {
        try {
            let response = await putApi({
                url: "dressing-room/measurements/",
                body: inputData,
            });
            if (response?.data?.status === 200) {
                showToast(response?.data?.message, 'success');
                setUserBodyMeasurements(response?.data?.data);
                return true;
            } else {
                showToastMessage(response);
                return false;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const getUserBodyMeasurements = async () => {
        try {
            let response = await getApi({
                url: "dressing-room/measurements/",
            });
            if (response?.isSuccess) {
                setUserBodyMeasurements(response?.data?.data);
                return true;
            } else {
                setUserBodyMeasurements(undefined);
                // showToastMessage(response);
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            // dispatch(setDisplayModal(false));
        }
    };

    const getClothes = async () => {
        try {
            let response = await getApi({
                url: "dressing-room/clothes/",
            });
            console.log(response?.data?.data?.clothes, 'daatt');
            if (response?.isSuccess) {
                setClothes(response?.data?.data?.clothes);
                return true;
            } else {
                // setUserBodyMeasurements(undefined);
                // showToastMessage(response);
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            // dispatch(setDisplayModal(false));
        }
    };

    const getClotheDetails = async (id: string) => {
        try {
            let response = await getApi({
                url: `dressing-room/clothes/${id}`,
            });
            console.log(response?.data?.data?.clothe_details, 'details');
            if (response?.isSuccess) {
                setClotheDetails(response?.data?.data?.clothe_details);
                return true;
            } else {
                // setUserBodyMeasurements(undefined);
                // showToastMessage(response);
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            // dispatch(setDisplayModal(false));
        }
    };

    const tryOn = async (id: string) => {
        try {
            let response = await getApi({
                url: `dressing-room/clothes/${id}/try_on`,
            });
            console.log(response?.data?.data, 'tryOn');
            if (response?.isSuccess) {
                setTryOnResult(response?.data?.data);
                return true;
            } else {
                // setUserBodyMeasurements(undefined);
                // showToastMessage(response);
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            // dispatch(setDisplayModal(false));
        }
    };

    return {
        getUserBodyMeasurements,
        userBodyMeasurements,
        updateUserBodyMeasurements,
        responsePutApi,
        responseGetApi,
        getClothes,
        clothes,
        getClotheDetails,
        clotheDetails,
        tryOn,
        tryOnResult,
    };
}
