import React from 'react';
import ImageTooltip from "../image-tooltip";
import {useTranslation} from "react-i18next";

const CirclePointer = (
    {
        id,
        top,
        left,
        textHorizontalPosition,
        textVerticalPosition = 'top',
        text,
        opacity
    }:
        {
            id: string;
            top: string;
            left: string;
            textHorizontalPosition?: 'left' | 'right';
            textVerticalPosition?: 'top' | 'bottom';
            text?: BodyMeasurementItem;
            opacity?: string;
        }
) => {
    const {i18n} = useTranslation();

    if (!text) return null;
    return (
        <div
            className={`flex-col flex justify-end ${textHorizontalPosition === 'left' ? 'items-end' : 'items-start'} absolute`}
            style={{top: top, left: left}}>
            {
                textVerticalPosition === 'top' &&
                <p
                    className={`text-xs rounded p-1 ${opacity} ${text.value !== 0 ? 'bg-emerald-50' : 'bg-pink-100'} ${text.value !== 0 ? 'text-green-700' : 'text-pink-700'} select-none cursor-default`}
                >
                    {text.value.toString() + ' ' + (text?.unit ?? '')}
                </p>
            }
            <ImageTooltip
                imageUrl={text?.image_url}
                title={i18n?.language === 'en' ? text?.en_title : text?.fa_title}
                description={i18n?.language === 'en' ? text?.en_description : text?.fa_description}
                altText={'aaa'}
            >
                <div className="relative flex items-center justify-center">
                    <div
                        className={`w-3 h-3 border-4 ${text.value !== 0 ? 'border-green-500' : 'border-pink-500'} rounded-full absolute`}></div>
                    <div className="w-2 h-2 border-4 border-white rounded-full absolute"></div>
                    <div
                        className={`w-1 h-1 ${text.value !== 0 ? 'bg-green-500' : 'bg-pink-500'} rounded-full animate-pulseGrow`}></div>
                </div>
            </ImageTooltip>

            {
                textVerticalPosition === 'bottom' &&
                <p
                    className={`text-xs rounded p-1 ${opacity} ${text.value !== 0 ? 'bg-emerald-50' : 'bg-pink-100'} ${text.value !== 0 ? 'text-green-700' : 'text-pink-700'} select-none cursor-default`}
                >
                    {text.value.toString() + ' ' + (text?.unit ?? '')}
                </p>
            }
        </div>
    )
}

export default CirclePointer;
