import React, {useEffect, useRef} from "react";
import Step01 from "../../../assets/images/step-01.png";
import Step02 from "../../../assets/images/step-02.png";
import Step03 from "../../../assets/images/step-03.png";
import "./styles.css";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useTranslation} from "react-i18next";
import Button from "../../atomic/buttons/button";
import {useNavigate} from "react-router-dom";
import GuidelineCard from "../../composite/guideline-card";
import ShopIcon from "../../../assets/icons/shop.svg";
import TieIcon from "../../../assets/icons/tie.svg";

gsap.registerPlugin(ScrollTrigger);

const Guideline = ({targetRef}: { targetRef: any }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const revealRefs = useRef<HTMLElement[]>([]);
    revealRefs.current = [];

    const addToRefs = (el: HTMLElement | null) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    const animateFrom = (elem: HTMLElement, direction: number = 1) => {
        let x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        elem.style.transform = `translate(${x}px, ${y}px)`;
        elem.style.opacity = "0";
        gsap.fromTo(
            elem,
            {x, y, autoAlpha: 0},
            {
                duration: 1.25,
                x: 0,
                y: 0,
                autoAlpha: 1,
                ease: "expo",
                overwrite: "auto",
            }
        );
    };

    const hide = (elem: HTMLElement) => {
        gsap.set(elem, {autoAlpha: 0});
    };

    useEffect(() => {
        revealRefs.current.forEach((elem) => {
            hide(elem);

            ScrollTrigger.create({
                trigger: elem,
                markers: false,
                onEnter: () => {
                    animateFrom(elem);
                },
                onEnterBack: () => {
                    animateFrom(elem, -1);
                },
                onLeave: () => {
                    // hide(elem);
                },
            });
        });
    }, []);

    return (
        <div className="mx-auto max-w-7xl" ref={targetRef}>
            <h1 className="my-10 gs_reveal text-center text-2xl font-medium" ref={addToRefs}>
                {t("guideline_title")}
            </h1>
            <div className="features">
                <GuidelineCard
                    addToRefs={addToRefs}
                    imageSrc={Step01}
                    title={t("step01_title")}
                    description={t("step01_description")}
                />
                <GuidelineCard
                    addToRefs={addToRefs}
                    imageSrc={Step02}
                    title={t("step02_title")}
                    description={t("step02_description")}
                    reverse
                />
                <GuidelineCard
                    addToRefs={addToRefs}
                    imageSrc={Step03}
                    title={t("step03_title")}
                    description={t("step03_description")}
                />
                <div className="w-full justify-center flex my-16 gap-2">
                    <Button
                        variety={'tertiary'}
                        title={t("dressing_room")}
                        onClick={() => navigate("/dressing-room")}
                        icon={<img src={TieIcon} alt={'shop'}/>}
                        customClassName={'!py-2'}
                    />
                    <Button
                        onClick={() => window.location.href = process.env.REACT_APP_SHOP_URL ?? 'https://shop.isketch.ir'}
                        title={t("shops")}
                        icon={<img src={ShopIcon} alt={'shop'}/>}
                        variety={'tertiary'}
                        customClassName={'!py-2'}
                    />
                </div>
            </div>
        </div>
    );
};

export default Guideline;
