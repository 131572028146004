import {useTranslation} from "react-i18next";
import Layout from "../../screen/layout";
import React, {useEffect} from "react";
import Title from "../../atomic/title";
import useDressingRoom from "../../../hooks/useDressingRoom";
import {useNavigate} from "react-router-dom";
import Button from "../../atomic/buttons/button";

const DressingRoom = () => {
    const {t} = useTranslation();
    const {getClothes, clothes} = useDressingRoom();
    const navigate = useNavigate();

    const getAllClothes = () => {
        try {
            getClothes();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAllClothes();
    }, []);

    return (
        <Layout>
            <>
                <Title context={t("dressing_room")}/>
                <div className={'grid grid-cols-5 gap-4 my-8'}>
                    {clothes?.map((clothe: Clothe) =>
                        <div className={'flex flex-col items-center justify-center shadow-xl rounded p-5 m-5'}>
                            <div>
                                <img
                                    src={process.env.REACT_APP_API_BASE_URL + clothe?.images[0].image_url}
                                    alt={clothe?.images[0].image_url}
                                    className={'w-44 h-44'}
                                />
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 border-b text-sm'}>
                                <p>{t('type')}: </p>
                                <p>{clothe?.type}</p>
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 border-b text-sm'}>
                                <p>{t('color')}: </p>
                                <p>{clothe?.color}</p>
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 border-b text-sm'}>
                                <p>{t('fabric')}: </p>
                                <p>{clothe?.fabric}</p>
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 border-b text-sm'}>
                                <p>{t('pattern')}: </p>
                                <p>{clothe?.pattern}</p>
                            </div>
                            <div className={'flex justify-between w-full my-1 p-1 text-sm'}>
                                <p>{t('sizes')}: </p>
                                {clothe?.sizes?.map((size) => <p
                                    className={'rounded-full px-2 py-1 bg-gray-100'}>{size?.size_label}</p>)}
                            </div>
                            <Button
                                variety={'primary'}
                                onClick={() => navigate(`clothes/${clothe?.id}`)}
                                title={t('details')}
                                customClassName={'mt-3'}
                            />
                        </div>
                    )}
                </div>
            </>
        </Layout>
    );
};

export default DressingRoom;
