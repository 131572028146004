import {useTranslation} from "react-i18next";
import Button from "../../atomic/buttons/button";
import Layout from "../../screen/layout";
import React, {useEffect, useState} from "react";
import Title from "../../atomic/title";
import {CSSTransition} from "react-transition-group";
import "../../../styles/animations.css";
import {ReactComponent as GoogleIcon} from "../../../assets/icons/google-icon.svg";
import {ReactComponent as RegisterIcon} from "../../../assets/icons/register.svg";
import {ReactComponent as LoginIcon} from "../../../assets/icons/login.svg";
import Logo from "../../../assets/images/logo/logo.png";
import LoginSection from "./sections/login";
import RegisterSection from "./sections/register";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setDisplayModal, setModalType} from "../../../redux/slices/displayModalsSlice";
import {useDispatch} from "react-redux";
import ResetPasswordSection from "./sections/reset-password";

const Authorization = ({resetPassword = false}: { resetPassword?: boolean }) => {
    const {t} = useTranslation();
    const [displayLoginSection, setDisplayLoginSection] = useState<boolean>(true);
    const [displayRegisterSection, setDisplayRegisterSection] =
        useState<boolean>(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch();

    const onGoogleLoginSuccess = () => {
        const GOOGLE_AUTH_URL = process.env.REACT_APP_GOOGLE_AUTH_URL;
        const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI
        const GOOGLE_OAUTH2_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID;

        const scope = [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
        ].join(' ');

        const params = {
            response_type: 'code',
            client_id: GOOGLE_OAUTH2_CLIENT_ID,
            redirect_uri: GOOGLE_REDIRECT_URI,
            prompt: 'select_account',
            access_type: 'offline',
            scope
        };

        let urlParams: string;
        // @ts-ignore
        urlParams = new URLSearchParams(params).toString();
        window.location.href = `${GOOGLE_AUTH_URL}?${urlParams}`;
    };

    const checkGoogleLogin = async () => {
        try {
            const accessToken = searchParams.get('access_token');
            const refreshToken = searchParams.get('refresh_token');
            const signup = searchParams.get('signup');
            if (accessToken && refreshToken) {
                localStorage.setItem('token', JSON.stringify({'access': accessToken, 'refresh': refreshToken}))
                setTimeout(() => {
                    if (signup === 'True') {
                        dispatch(setModalType('complete-profile'))
                        dispatch(setDisplayModal(true))
                    } else {
                        navigate('/')
                    }
                }, 1000)
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        checkGoogleLogin();
    }, []);

    return (
        <Layout>
            <div className="flex-col items-center justify-center min-w-[28%]">
                <div className="grid grid-cols-1 gap-2 justify-center text-center">
                    <img
                        src={Logo}
                        alt={"logo"}
                        className="object-fill h-[44px] mx-auto"
                    />
                    <h1 style={{fontFamily: 'DancingScript'}} className="font-bold text-[2rem]">iSketch</h1>
                </div>
                <div
                    className="grid grid-cols-1 rounded-md shadow-md py-8 px-5 md:px-10 gap-4 justify-center items-center !min-h-[90%]">
                    <Title
                        context={
                            resetPassword ? t("reset_password") : displayLoginSection
                                ? t("sign_in")
                                : t("sign_up")
                        }
                    />
                    {resetPassword ? (<ResetPasswordSection/>) :
                        (<><CSSTransition
                            in={displayLoginSection}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                            onExited={() => setDisplayRegisterSection(true)}
                        >
                            <LoginSection/>
                        </CSSTransition>

                            <CSSTransition
                                in={displayRegisterSection}
                                timeout={300}
                                classNames="fade"
                                unmountOnExit
                                onExited={() => setDisplayLoginSection(true)}
                            >
                                <RegisterSection/>
                            </CSSTransition>
                            <hr/>
                            <div className="w-full flex justify-center space-x-1 transition-all duration-300">
                                <Button
                                    icon={<GoogleIcon width={24} height={24}/>}
                                    onClick={() => onGoogleLoginSuccess()}
                                    title="Sign in with Google"
                                    variety="tertiary"
                                    customClassName="!py-2"
                                />

                                <CSSTransition
                                    in={displayLoginSection}
                                    timeout={300}
                                    classNames="fade"
                                    unmountOnExit
                                    onExited={() => setDisplayRegisterSection(true)}
                                >
                                    <Button
                                        icon={<RegisterIcon width={24} height={24}/>}
                                        onClick={() => {
                                            setDisplayLoginSection(false);
                                        }}
                                        title={t("sign_up")}
                                        variety="tertiary"
                                        customClassName="!py-2"
                                    />
                                </CSSTransition>
                                <CSSTransition
                                    in={displayRegisterSection}
                                    timeout={300}
                                    classNames="fade"
                                    unmountOnExit
                                    onExited={() => setDisplayLoginSection(true)}
                                >
                                    <Button
                                        icon={<LoginIcon width={24} height={24}/>}
                                        onClick={() => setDisplayRegisterSection(false)}
                                        title={t("sign_in")}
                                        variety="tertiary"
                                        customClassName="!py-2"
                                    />
                                </CSSTransition>
                            </div>
                        </>)}
                </div>
            </div>
        </Layout>
    );
};

export default Authorization;
