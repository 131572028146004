import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "fa",
    fallbackLng: "fa",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                authorization: "Authorization",
                authorize: "Authorize",
                sign_in: "Sign in",
                sign_up: "Sign up",
                start: "Start",
                store: "Store",
                welcome_title: "Welcome to iSketch",
                welcome_description: "This is where you can set your private closet!",
                dressing_room_title: "Give us your size!",
                submit: "Submit",
                email: "Email",
                emailAddressOrPhoneNumber: "Email or Phone Number",
                password: "Password",
                retype_password: "Retype Password",
                current_password: "Current Password",
                new_password: "New Password",
                retype_new_password: "Retype New Password",
                change_password: "Change Password",
                password_hint:
                    "Your password should be at least 8 characters long and include both numbers and letters.",
                retype_password_hint: "Your retype password must be the same as your password.",
                change_password_description:
                    "If you remember your current password, you can change it here.",
                recovery_password: "Forgot Password",
                send_request: "Send Request",
                back: "Back",
                to: "to",
                in: "in",
                copyright: `Copyright © ${new Date().getFullYear()} iSketch Inc. All rights reserved.`,
                privacy_policy: "Privacy Policy",
                terms_of_use: "Terms of use",
                location: "Iran",
                about_us: "About Us",
                contact_us: "Contact Us",
                step01_title: "Create a User Account",
                step02_title: "Submit Your Sizes in the Profile",
                step03_title: "Shop Online with Confidence",
                step01_description:
                    "In the first step, you need to register in the application. You can do this using your social media account, email, or phone number.",
                step02_description:
                    "Next, find and submit your body sizes in your profile. The sizes we need include chest width, height, and more.",
                step03_description:
                    "Now you can shop online with a high level of certainty that you will find clothes in your size.",
                private_information: "Private Information",
                body_sizes: "Body Sizes",
                notifications: "Notifications",
                sign_out: "Sign out",
                body_sizes_description_1:
                    "In this section, you need to enter the measurements of different parts of your body.",
                body_sizes_description_2:
                    "Don't worry! We're here to help you get accurate measurements. Just remember, the more precise and detailed your measurements are, the better we can help you find clothes that fit perfectly in the next steps.",
                body_sizes_description_3:
                    "So, roll up your sleeves, get your tape measure ready, and dive into the fun of measuring!",
                shoulder_width: "Shoulder Width",
                chest_width: "Chest Width",
                waist: "Waist",
                short_sleeve_length: "Sleeve Length (short)",
                long_sleeve_length: "Sleeve Length (long)",
                torso_length: "Torso Length",
                hips: "Hips",
                inseam: "Inseam",
                outseam: "Outseam",
                thigh: "Thigh",
                rise: "Rise",
                armhole: "Armhole",
                shoulder_width_hint:
                    "Measure from the tip of one shoulder to the tip of the other shoulder",
                chest_width_hint:
                    "Measure the circumference of the chest at its fullest part",
                waist_hint:
                    "Measure the circumference at the narrowest part of the waist",
                sleeve_length_hint:
                    "Measure from the tip of the shoulder to where you want the sleeve to end",
                torso_length_hint:
                    "Measure from the base of the neck to the bottom of the torso",
                hips_hint: "Measure the circumference at the fullest part of the hips",
                inseam_hint:
                    "Measure from the inside of the leg, from the crotch to the ankle",
                outseam_hint:
                    "Measure from the waist to the ankle along the outside of the leg",
                thigh_hint:
                    "Measure the circumference at the fullest part of the thigh",
                rise_hint: "Measure from the crotch to the waist",
                armhole_hint:
                    "Measure the circumference of the fullest part of the arm",
                private_information_description:
                    "You can update your account information here",
                profile: "Profile",
                first_name: "First Name",
                last_name: "Last Name",
                mobile_number: "Phone Number",
                update_information: "Update Information",
                notifications_description: "You can find your notifications in here",
                dressing_room: "Dressing Room",
                guideline_title: "You can start your journey with some simple clicks",
                go: "Let's go",
                waiting: "Please Wait...",
                verification_account: "Verification Account",
                verification_account_message_start:
                    "Please enter the code you received in email address: ",
                verification_account_message_end: " to verify your account",
                cancel: "Cancel",
                complete_profile: "Complete Profile",
                complete_profile_message: "Please complete your profile information",
                male: "Male",
                female: "Female",
                required: "require",
                passwords_not_match: "Oops! Your passwords didn't get along. Try again!",
                passwords_empty: "Hold up! You forgot to enter your passwords. Give it another go!",
                email_address_or_phone_number_not_valid: "Email address or phone number is invalid",
                next: 'Next',
                previous: 'Previous',
                reset_password: "Reset Password",
                otp_code_hint: "Please enter OTP Code you received",
                upper_body: 'Upper Body Sizes',
                lower_body: 'Lower Body Sizes',
                head: 'Head Sizes',
                update: 'Update',
                upper_body_description: "t-shirts, hoodies, coats, etc.",
                lower_body_description: "pants, underwear, etc.",
                head_description: "Accessories like hats, glasses, etc.",
                help: 'Help',
                finish: 'Finish',
                measurement_guideline_title: "Measurement Guidelines",
                measurement_guideline_description: "Follow these steps to ensure accurate body measurements for the perfect fit.",
                measurement_guideline_tips: [
                    {
                        "title": "Use a flexible measuring tape",
                        "description": "Trust me, a fabric or plastic tape is your best friend—unless you want your body measurements to be as rigid as a metal tape measure!"
                    },
                    {
                        "title": "Measure snugly, but don’t turn into a sausage",
                        "description": "Make sure the tape hugs your body like a gentle bear hug, not like it’s trying to squeeze the life out of you."
                    },
                    {
                        "title": "Stand naturally",
                        "description": "Strike a pose, but make it relaxed! No need to stand like a superhero—unless that’s how you normally stand."
                    },
                    {
                        "title": "Measure over light clothing or underwear",
                        "description": "Skip the winter coat for this one, unless you’re planning to hibernate in it!"
                    },
                    {
                        "title": "Measure at the end of the day",
                        "description": "Your feet like to swell up during the day, so it's best to measure them when they've reached their full 'end-of-day glory'! That way, you'll know your shoes can handle anything!"
                    }
                ],
                ok: 'OK',
                shops: 'Shops',
                not_found_404_title: 'Oops! Page Not Found',
                not_found_404_description: 'It looks like you\'ve ventured into uncharted territory.\n Don\'t worry, we\'ll guide you back on track!',
                not_found_404_tips_title: 'Here\'s what you can do:',
                not_found_404_tips: [
                    "Check the URL to make sure it’s correct.",
                    "Head back to our homepage to find what you need.",
                    "Or, contact us if you need further assistance."
                ],
                previous_value: 'Previous Value',
                edit: 'Edit',
                hidden: 'Hidden',
                visible: 'Visible',
            },
        },
        fa: {
            translation: {
                authorization: "احراز هویت",
                authorize: "ثبت نام / ورود",
                sign_in: "ورود",
                sign_up: "ثبت‌نام",
                start: "شروع",
                store: "مغازه",
                welcome_title: "خوش آمدید",
                welcome_description:
                    "اینجا جاییه که میتونی کمد لباس‌های خودت رو داشته باشی",
                dressing_room_title: "!سایزت رو وارد کن",
                submit: "ثبت",
                email: "ایمیل",
                emailAddressOrPhoneNumber: "ایمیل یا شماره تماس",
                password: "رمز عبور",
                retype_password: "تکرار رمز عبور",
                recovery_password: "بازیابی رمز عبور",
                current_password: "رمز عبور فعلی",
                new_password: "رمز عبور جدید",
                retype_new_password: "دوباره رمز عبورر جدید",
                change_password: "تغییر رمز عبور",
                password_hint:
                    "رمز عبور شما باید حداقل ۸ کاراکتر باشد و شامل اعداد و حروف باشد",
                retype_password_hint: "تکرار رمز عبور شما باید با رمز عبور شما یکسان باشد.",
                change_password_description:
                    "اگر رمز عبور فعلی خود را به یاد دارید، می‌توانید آن را اینجا تغییر دهید.",
                send_request: "ارسال درخواست",
                back: "بازگشت",
                to: "به",
                in: "در",
                copyright: `کپی‌رایت © ${new Date().getFullYear()} شرکت آی اِسکتچ. تمامی حقوق محفوظ است.`,
                privacy_policy: "سیاست حفظ حریم خصوصی",
                terms_of_use: "شرایط استفاده",
                location: "ایران",
                about_us: "درباره ما",
                contact_us: "ارتباط با ما",
                step01_title: "ایجاد حساب کاربری",
                step02_title: "ثبت اندازه‌ها در پروفایل",
                step03_title: "خرید آنلاین با اطمینان",
                step01_description:
                    "در اولین مرحله، شما باید در اپلیکیشن ثبت‌نام کنید. می‌توانید این کار را با استفاده از حساب شبکه‌های اجتماعی، ایمیل یا شماره تلفن خود انجام دهید.",
                step02_description:
                    "در مرحله بعد، اندازه‌های بدن خود را در پروفایل وارد کنید. اندازه‌هایی که نیاز داریم شامل عرض سینه، قد، و غیره می‌باشند.",
                step03_description:
                    "اکنون می‌توانید با اطمینان بالا که لباس‌های مناسب سایز خود را پیدا خواهید کرد، به صورت آنلاین خرید کنید.",
                private_information: "اطلاعات شخصی",
                body_sizes: "اندازه های بدن",
                notifications: "اعلانها",
                sign_out: "خروج",
                body_sizes_description_1: "توی این قسمت باید اندازه‌های قسمت‌های مختلف بدنت رو وارد کنی.",
                body_sizes_description_2: "نگران نباش! ما اینجاییم تا بهت کمک کنیم که اندازه‌ها رو دقیق بگیری. فقط یادت نره که هرچی اندازه‌های دقیق‌تر و بیشتری وارد کنی، توی مراحل بعدی بهتر می‌تونیم بهت کمک کنیم که لباس‌های سایز خودت رو پیدا کنی.",
                body_sizes_description_3: "حالا بیفت به کار، مترت رو آماده کن و بزن به دل اندازه‌گیری!",
                shoulder_width: "اندازه عرض شانه",
                chest_width: "اندازه دور سینه",
                waist: "اندازه دور کمر",
                short_sleeve_length: "اندازه طول آستین کوتاه",
                long_sleeve_length: "اندازه طول آستین بلند",
                torso_length: "طول تنه",
                hips: "اندازه دور باسن",
                inseam: "طول داخلی پا",
                outseam: "طول خارجی پا",
                thigh: "اندازه دور ران",
                rise: "ارتفاع شلوار",
                armhole: "اندازه دور بازو",
                shoulder_width_hint: "اندازه‌گیری از نوک یک شانه تا نوک شانه دیگر",
                chest_width_hint: "اندازه دور سینه را در قسمت پرتر سینه بگیرید",
                waist_hint: "اندازه دور کمر را در باریک‌ترین قسمت کمر بگیرید",
                sleeve_length_hint:
                    "اندازه‌گیری از نوک شانه تا جایی که می‌خواهید آستین به پایان برسد",
                torso_length_hint: "اندازه‌گیری از پایه گردن تا پایین تنه",
                hips_hint: "اندازه دور پرترین قسمت باسن را بگیرید",
                inseam_hint: "از داخل پا، از فاق تا مچ پا اندازه‌گیری کنید",
                outseam_hint:
                    "از کمر تا مچ پا در امتداد قسمت بیرونی پا اندازه‌گیری کنید",
                thigh_hint: "دور پرترین قسمت ران را اندازه بگیرید",
                rise_hint: "از فاق تا کمر اندازه‌گیری کنید،",
                armhole_hint: "اندازه‌گیری دور پرترین قسمت بازو",
                private_information_description:
                    "شما می‌توانید اطلاعات حساب کاربری خود را در اینجا به‌روز کنید",
                profile: "پروفایل",
                first_name: "نام",
                last_name: "نام خانوادگی",
                mobile_number: "تلفن همراه",
                update_information: "بروزرسانی اطلاعات",
                notifications_description: "اینجا میتونی اعلان هات رو مدیریت کنی",
                dressing_room: "اتاق لباس من",
                guideline_title: "با چند کلیک ساده میتونی شروع کنی",
                go: "بزن بریم",
                waiting: "یه کم صبر کنید...",
                verification_account: "تایید نهایی",
                verification_account_message_start: "لطفاً کدی که در آدرس ایمیل: ",
                verification_account_message_end:
                    " دریافت کرده‌اید را برای تأیید حساب خود وارد کنید",
                cancel: " انصراف",
                complete_profile: "تکمیل پروفایل",
                complete_profile_message: "لطفاً اطلاعات پروفایل خود را تکمیل کنید",
                male: "مرد",
                female: "زن",
                required: "اجباری",
                passwords_not_match: "ای وای! رمزهای عبور شما همخوانی ندارند. دوباره امتحان کنید!",
                passwords_empty: "صبر کن! رمزهای عبور رو فراموش کردی وارد کنی. دوباره امتحان کن!",
                email_address_or_phone_number_not_valid: "ایمیل یا شماره تماس صحیح نیست",
                next: 'بعدی',
                previous: 'قبلی',
                reset_password: 'بازیابی رمز عبور',
                otp_code_hint: 'لطفا کد تایید را وارد کنید',
                upper_body: 'اندازه‌های بالا تنه',
                lower_body: 'اندازه‌های پایین تنه',
                head: 'اندازه‌های سر',
                update: 'بروزرسانی',
                upper_body_description: "تی‌شرت، هودی، کت و غیره.",
                lower_body_description: "شلوار، لباس زیر و غیره.",
                head_description: "لوازمی مانند کلاه، عینک و غیره.",
                help: "راهنما",
                finish: "اتمام",
                measurement_guideline_title: "راهنمای اندازه‌گیری",
                measurement_guideline_description: "برای به دست آوردن اندازه‌های دقیق بدن و یک انتخاب مناسب، این مراحل را دنبال کنید.",
                measurement_guideline_tips: [
                    {
                        "title": "از یک متر نواری انعطاف‌پذیر استفاده کنید",
                        "description": "متر پارچه‌ای یا پلاستیکی مثل رفیق صمیمی‌تونه—مگر اینکه بخواهید اندازه‌هاتون مثل متر فلزی خشک و بی‌انعطاف باشه!"
                    },
                    {
                        "title": "اندازه‌گیری‌ها را محکم اما نه سفت بگیرید",
                        "description": "مطمئن بشید که متر مثل یه آغوش گرم بدن‌تون رو بغل کرده، نه مثل اینکه می‌خواد شما رو به سوسیس تبدیل کنه!"
                    },
                    {
                        "title": "به طور طبیعی بایستید",
                        "description": "یه ژست بگیرید، ولی راحت باشید! لازم نیست مثل ابرقهرمان بایستید—مگر اینکه همیشه این‌جوری می‌ایستید."
                    },
                    {
                        "title": "اندازه‌گیری‌ها را روی لباس‌های سبک یا زیرپوش انجام دهید",
                        "description": "بهتره کاپشن زمستونی رو بیخیال بشید، مگر اینکه قصد داشته باشید باهاش زمستون‌خوابی کنید!"
                    },
                    {
                        "title": "اندازه‌گیری‌ها را در انتهای روز انجام دهید",
                        "description": "پاهای شما در طول روز کمی ورم می‌کنن، پس بهتره آخر شب اندازه بگیرید تا مطمئن بشید کفش‌هاتون حتی بعد یه روز سخت هم جا دارن!"
                    }
                ],
                ok: 'باش',
                shops: 'فروشگاه‌ها',
                not_found_404_title: 'اوه! صفحه پیدا نشد',
                not_found_404_description: 'به نظر می‌رسد که وارد قلمرو ناشناخته‌ای شده‌اید.\n نگران نباشید، ما شما را به مسیر درست هدایت می‌کنیم!',
                not_found_404_tips_title: 'می‌توانید این کارها را انجام دهید:',
                not_found_404_tips: [
                    "آدرس URL را بررسی کنید تا مطمئن شوید صحیح است.",
                    "به صفحه اصلی ما بازگردید تا آنچه نیاز دارید پیدا کنید.",
                    "یا، اگر نیاز به کمک بیشتری دارید، با ما تماس بگیرید."
                ],
                previous_value: 'مقدار قبلی',
                edit: 'ویرایش',
                hidden: 'مخفی',
                visible: 'نمایش',
            },
        },
    },
});

export default i18n;
