import React, {ReactNode, useState} from 'react';

const ImageTooltip = (
    {imageUrl, altText, children, title, description}:
        { imageUrl: string; altText: string; children: ReactNode; title?: string; description?: string; }
) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div className="relative inline-block bg-transparent" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children} {/* The element that triggers the tooltip */}

            {/* Tooltip */}
            {showTooltip && (
                <div
                    className="absolute left-1/2 transform -translate-x-1/2 -translate-y-full mt-2 w-48 p-2 bg-white p-5 shadow-md text-center rounded-lg z-50">
                    <img src={imageUrl} alt={altText} className="w-full h-auto rounded"/>
                    {title && <p className={'font-bold'}>{title}</p>}
                    {description && <p className={'text-xs'}>{description}</p>}
                </div>
            )}
        </div>
    );
};

export default ImageTooltip;
